'use client';
import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import { pageview } from '../../libs/gtm';

export default function Analytics() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  var content = '';
  var page = '';

  function formatString(inputString: string): string {
    // Extract content between furthest right slashes
    const match = inputString.match(/\/([^/]+)\/$/);

    if (match) {
      // Extract the matched content
      const content = match[1];

      // Check if the trimmed string contains a hyphen
      if (content.includes('-')) {
        // Split the string by "-"
        const words = content.split('-');

        // Capitalize the first letter of each word and join them with a space
        const formattedString = words
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');

        return formattedString;
      } else {
        // If there is no hyphen, simply capitalize the first letter
        return content.charAt(0).toUpperCase() + content.slice(1);
      }
    }

    // Return the input string if no match is found
    return inputString;
  }

  if (pathname == '/') {
    page = 'Homepage';
    content = 'Homepage';
  } else if (pathname.includes('/tag')) {
    page = 'Tag';
    content = formatString(pathname);
  } else if (pathname.includes('/about')) {
    page = 'About';
    content = 'Connor Phillips';
  } else if (pathname.includes('/page')) {
    page = 'Page';
    content = formatString(pathname);
  } else if (pathname.includes('/portfolio')) {
    page = 'Portfolio';
    content = formatString(pathname);
  } else {
    page = 'Post';
    content = formatString(pathname);
  }

  useEffect(() => {
    if (pathname) {
      return pageview(pathname, content, page);
    }
  }, [pathname, searchParams, content, page]);

  if (process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production') {
    return null;
  }
}
